import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import styled from '../styled';
import { MdxArticle, SanityPost } from '../types/Article';
import { ResponsiveImage } from './ResponsiveImage';

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    font-size: ${theme.typography.h5.fontSize};
    font-weight: ${theme.typography.fontWeightBold};

    h2 {
      font-size: ${theme.typography.h5.fontSize};
      font-weight: ${theme.typography.fontWeightBold};
    }
  `};
`;
const PostDate = styled('p')`
  ${({ theme }) => css`
    color: ${theme.palette.grey[400]};
    font-size: ${theme.typography.body2.fontSize};
    position: absolute;
    bottom: ${theme.spacing(2)}px;
  `};
`;

const PaperInner = styled('div')`
  padding-top: 18px;
  padding-bottom: 50px;
  text-align: left;
`;

const PostSummary = styled('p')`
  ${({ theme }) => css`
    color: ${theme.palette.grey[600]};
    font-size: ${theme.typography.body2.fontSize};
    line-height: 28px;
  `};
`;

const PostLinkWrapper = styled('div')`
  height: 100%;
  position: relative;

  img {
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  @media (max-width: 800px) {
    img {
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
`;

type PostLinkType = {
  _updatedAt?: string;
  publishedAt?: string;
} & Pick<SanityPost, 'title' | 'description' | 'mainImage' | 'slug'>;

export const SanityPostLink = ({ post }: { post: PostLinkType }) => (
  <PostLinkWrapper>
    <Link to={`/blog/${post.slug.current}/`}>
      {post.mainImage && post.mainImage.image ? (
        <ResponsiveImage
          src={post.mainImage.image.src}
          alt={post.mainImage.alt}
        />
      ) : null}
    </Link>
    <PaperInner>
      <StyledLink to={`/blog/${post.slug.current}/`}>
        <h2>{post.title}</h2>
      </StyledLink>
      <PostSummary>{post.description}</PostSummary>
      <PostDate>Published {post.publishedAt || post._updatedAt}</PostDate>
    </PaperInner>
  </PostLinkWrapper>
);

export const PostLink = ({ post }: { post: MdxArticle }) => (
  <PostLinkWrapper>
    <Link to={post.fields.slug}>
      <ResponsiveImage
        src={post.frontmatter.image}
        alt={post.frontmatter.title}
      />
    </Link>
    <PaperInner>
      <StyledLink to={post.fields.slug}>
        <h2>{post.frontmatter.title}</h2>
      </StyledLink>
      <PostSummary>{post.frontmatter.summary}</PostSummary>
      <PostDate>Published {post.frontmatter.date}</PostDate>
    </PaperInner>
  </PostLinkWrapper>
);

const FeaturedPostLinkWrapper = styled('div')`
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;

  time {
    color: ${(p) => p.theme.palette.grey[400]};
  }

  img {
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;

    img {
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
`;

export const FeaturedPostLink = ({
  post,
  prefix = 'blog'
}: {
  post: SanityPost;
  prefix?: string;
}) => (
  <FeaturedPostLinkWrapper>
    <Link to={`/${prefix}/${post.slug.current}/`}>
      <ResponsiveImage alt={post.title} src={post.mainImage.image.src} />
    </Link>
    <PaperInner>
      <StyledLink to={`/${prefix}/${post.slug.current}/`}>
        <h2>{post.title}</h2>
      </StyledLink>
      <PostSummary>{post.description}</PostSummary>
      <time>Published {post.publishedAt}</time>
    </PaperInner>
  </FeaturedPostLinkWrapper>
);
