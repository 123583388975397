import React from 'react';
import { css } from '@emotion/core';
import { graphql } from 'gatsby';
import { SanityPost } from '../../types/Article';
import Content from '../../layouts/main';
import styled from '../../styled';
import { SEO } from '../../components/SEO';
import { FeaturedPostLink } from '../../components/PostLink';
import { Tag } from '../../types/Tag';
import { AffiliateProgramList } from '../../types/AffiliateProgramList';
import PageHead from '../../components/PageHead';
import { Typography } from '@material-ui/core';

const Heading = styled('span')`
  display: inline-block;
  color: #4f4f4f;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
`;

const PostContainer = styled('div')`
  ${({ theme }) => css`
    max-width: 1200px;
    padding: ${theme.spacing(2)}px;
    margin: ${theme.spacing(10)}px auto;
  `};
`;

type PageQueryData = {
  allSanityAffiliateNiche: {
    edges: {
      node: AffiliateProgramList;
    }[];
  };
  allSanityPost: {
    edges: {
      node: SanityPost;
    }[];
  };
  allTagYaml: {
    edges: {
      node: Tag;
    }[];
  };
};

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(8)}px;
  grid-row-gap: ${(p) => p.theme.spacing(8)}px;
  margin-bottom: ${(p) => p.theme.spacing(8)}px;

  & > div {
    margin-bottom: 12px;
  }

  @media (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`;

const HeadingWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;

  a {
    color: #767e87;
  }
`;

const IndexPage = ({ data: { allSanityPost } }: { data: PageQueryData }) => {
  const sanityPosts = allSanityPost.edges
    .filter((e) => !!e.node.publishedAt)
    .map((e) => e.node);

  const caseStudies = sanityPosts.filter((p) =>
    p.categories.find((c) => c.slug.current === 'case-studies')
  );

  return (
    <Content>
      <>
        <SEO
          title="Customers & Publisher Success Stories"
          description="Affilimate serves hundreds of publishers, including solo creators, owner operators, and enterprise media publishers. Here are a few of their success stories so far."
          pathname="/customers/"
        />
        <PageHead>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              maxWidth: '700px',
              margin: '0 auto 36px',
              fontWeight: 900
            }}
          >
            Customer stories
          </Typography>
          <Typography
            paragraph
            style={{ maxWidth: '600px', padding: '24px', margin: '0 auto' }}
          >
            Affilimate serves hundreds of publishers, including solo creators,
            owner operators, and enterprise media publishers. Here are a few of
            their success stories so far.
          </Typography>
        </PageHead>
        <PostContainer>
          <HeadingWrapper>
            <Heading>Creators and Operators</Heading>
          </HeadingWrapper>
          {caseStudies.map((post) => (
            <FeaturedPostLink
              key={post.slug.current}
              prefix="customers"
              post={post}
            />
          ))}
        </PostContainer>
      </>
    </Content>
  );
};

export const pageQuery = graphql`
  query {
    allTagYaml {
      edges {
        node {
          name
          slug
        }
      }
    }
    allSanityPost(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          publishedAt(formatString: "MMMM DD, YYYY")
          lastUpdated(formatString: "MMMM DD, YYYY")
          featured
          categories {
            slug {
              current
            }
          }
          mainImage {
            image {
              src
            }
          }
          title
          description
          slug {
            current
          }
        }
      }
    }
  }
`;

export default IndexPage;
